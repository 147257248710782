import styled from 'styled-components';
import { colors } from '../../utils/globals';

export const StyledHome = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  button {
    background: ${colors.blaze};
    color: ${colors.white};
    font-weight: 700;
    padding: 10px 30px;
    border-radius: 5px;

    &:hover {
      background: ${colors.crusta};
    }
  }
`;

export default StyledHome;
