import React from 'react';
import { PulseLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import { colors } from '../../utils/globals';
import { StyledSpinner } from './style';

// @ts-ignore
const Spinner = ({ color }) => (
  <StyledSpinner>
    <PulseLoader color={color} />
  </StyledSpinner>
);

Spinner.propTypes = {
  color: PropTypes.string,
};

Spinner.defaultProps = {
  color: colors.manatee,
};

export default Spinner;
