import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import axios from '../../../utils/axios';
import { StyledRequestPassword } from './style';

const RequestPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const navigate = useNavigate();
  const onSubmit = async (data: any) => {
    try {
      await axios.post('/password-request', data);
      toast.success('Veuillez vérifier votre boite mail');
      navigate('/login');
    } catch (error) {
      toast.error('Email incorrecte !');
      console.error(error);
    }
  };
  return (
    <StyledRequestPassword>
      <h1>Changement de mot de passe</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-wrapper">
          <label htmlFor="email">E-mail *</label>
          <input
            type="text"
            id="email"
            {...register('email', { required: true })}
          />
          {errors.email && <p className="error">Ce champs est obligatoire</p>}
        </div>
        <button type="submit">Demander</button>
        <Link to={'/login'}>Annuler</Link>
      </form>
    </StyledRequestPassword>
  );
};

export default RequestPassword;
