import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import axios from '../../../utils/axios';
import { StyledLogin } from './style';

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const navigate = useNavigate();
  const onSubmit = async (data: any) => {
    try {
      const response = await axios.post('/login', data);
      localStorage.setItem('user', JSON.stringify(response.data?.user));
      localStorage.setItem('access_token', JSON.stringify(response.data?.access_token));
      toast.success('Bienvenue sur TakeQR');
      navigate('/');
    } catch (error) {
      toast.error('Email ou mot de passe incorrecte !');
      console.error(error);
    }
  };
  return (
    <StyledLogin>
      <h1>TakeQR</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-wrapper">
          <label htmlFor="email">E-mail *</label>
          <input
            type="text"
            id="email"
            {...register('email', { required: true })}
          />
          {errors.email && <p className="error">Ce champs est obligatoire</p>}
        </div>
        <div className="input-wrapper">
          <label htmlFor="password">Mot de passe *</label>
          <input
            type="password"
            id="password"
            {...register('password', { required: true })}
          />
          {errors.password && <p className="error">Ce champs est obligatoire</p>}
        </div>
        <button type="submit">Se connecter</button>
        <Link to={'/sign-up'}>Inscription</Link>
        <Link to={'/request-password'}>Mot de passe oublié ?</Link>
      </form>
    </StyledLogin>
  );
};

export default Login;
