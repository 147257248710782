import styled from 'styled-components';
import { colors } from '../../../utils/globals';

export const StyledLogin = styled.div`
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right top, ${colors.blaze}, ${colors.crusta});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  border-radius: 8px;
  h1 {
    color: ${colors.white};
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    width: 300px;

    .input-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      label {
        font-weight: 700;
        color:${colors.white};
      }
      
      input {
        height: 50px;
        border-radius: 5px;
        border: 2px solid ${colors.white};
        padding: 0 10px;
      }
      
      p {
        &.error {
            color: ${colors.white};
        }
      }
    }
    
    button {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1.3rem;
      color:${colors.white};
      border: 2px solid ${colors.white};
      background: ${colors.blaze};
      padding: 10px 20px;
      border-radius: 5px;
      &:hover {
        background: ${colors.crusta};
      }
    }
    
    a {
      color: ${colors.white};
      text-decoration: underline;
      &:visited {
        color: ${colors.white};
      }
    }
  }
`;

export default StyledLogin;
