import React from 'react';
import { PulseLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { colors } from '../../utils/globals';
import { StyledList } from './style';
import { ReactComponent as See } from '../../svgs/see.svg';
import { ReactComponent as Pen } from '../../svgs/pen.svg';
import { ReactComponent as Cross } from '../../svgs/cross.svg';
import AddBtn from './AddBtn';
import axios from '../../utils/axios';

// @ts-ignore
const List = ({
  resource, data, fields, refresh,
}) => {
  const deleteResource = async (id) => {
    try {
      await axios.delete(`/${resource}/${id}`);
      toast.success('Supprimé avec succès');
    } catch (e) {
      console.log('error');
    }
    refresh();
  };
  return (
    <StyledList>
      <AddBtn resource={resource} />
      <table border={0}>
        <tr>
          {
            fields?.map((item) => (
              <th key={item?.id}><span>{item?.label}</span></th>
            ))
          }

          <th><span>Actions</span></th>
        </tr>

        {
          data?.map((item) => (
            <tr key={item?.id}>
              {
                fields?.map((field) => (
                  <td key={field?.id}>
                    <span>{item[field?.key]}</span>
                  </td>
                ))
              }
              <td>
                <Link to={`/${resource}/${item?._id}`}><See /></Link>
                <Link to={`/${resource}/edit/${item?._id}`}><Pen /></Link>
                <button type="button" aria-label="Delete" title="Supprimer" onClick={() => deleteResource(item?._id)}>
                  <Cross />
                </button>
              </td>
            </tr>
          ))
        }
      </table>
    </StyledList>
  );
};
List.propTypes = {
  resource: PropTypes.string.isRequired,
  data: PropTypes.array,
  fields: PropTypes.array.isRequired,
  refresh: PropTypes.func.isRequired,
};

List.defaultProps = {
  data: [],
};

export default List;
