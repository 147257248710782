import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigate, Outlet } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import axios from '../../utils/axios';
import { StyledUsers } from './style';
import Spinner from '../../component/Spinner';
import Layout from '../../component/Layout';
import List from '../../component/List';

const fakeData = [
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
  {
    firstname: 'aa', lastname: 'bb', email: 'aa@bb.com', _id: 'dsqdjazxx874', roles: 'user',
  },
];
const Users = () => {
  const navigate = useNavigate();
  const user = useLoaderData();
  const [users, setUsers] = useState();
  const [renderCounter, setRenderCounter] = useState(0);
  const refresh = () => {
    setRenderCounter(renderCounter + 1);
  };
  const getUsers = async () => {
    setUsers(null);
    try {
      const resp = await axios.get('/users');
      setUsers(resp?.data || []);
      // setUsers(fakeData);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUsers();
  }, [renderCounter]);

  return (
    <Layout>
      <StyledUsers>
        {
          users ? (
            <List
              refresh={refresh}
              resource={'users'}
              data={users}
              fields={[
                { key: 'firstname', label: 'Prénom' },
                { key: 'lastname', label: 'Nom' },
                { key: 'email', label: 'Email' },
                { key: 'roles', label: 'Rôle' },
              ]}
            />
          ) : (<Spinner />)
        }
        <Outlet />
      </StyledUsers>
    </Layout>
  );
};

export default Users;
