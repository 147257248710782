import React from 'react';
import {
  Link, useLoaderData, useLocation, useNavigate,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledDrawer } from './style';
import { ReactComponent as Home } from '../../svgs/home.svg';
import { ReactComponent as Users } from '../../svgs/users.svg';
import { ReactComponent as Store } from '../../svgs/store.svg';
import { ReactComponent as TurnOff } from '../../svgs/turnOff.svg';
import { ReactComponent as User } from '../../svgs/user.svg';
import { ReactComponent as LeftChevron } from '../../svgs/chevron-left.svg';

const Drawer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useLoaderData();
  console.log(location);
  const logOut = () => {
    localStorage.clear();
    navigate('/login');
    toast.success('à bientôt !');
  };
  return (
    <StyledDrawer>
      <div className="logo-wrapper">
        <figure>
          <img src="/qrLogo.png" alt="logo" />
        </figure>
      </div>
      <div className="nav-wrapper">
        <div className={`nav-wrapper_item ${location?.pathname === '/' ? 'active' : ''}`}>
          <Link to="/">
            <Home />
            <span>Accueil</span>
          </Link>
        </div>

        {
          location?.pathname === '/' && (
            <div className={`nav-wrapper_item ${location?.pathname.includes('users') ? 'active' : ''}`}>
              <Link to="/users">
                <Users />
                <span>Utilisateurs</span>
              </Link>
            </div>
          )
        }

        {
          location?.pathname === '/' && (
            <div className={`nav-wrapper_item ${location?.pathname.includes('apps') ? 'active' : ''}`}>
              <Link to="/apps">
                <Store />
                <span>Applications</span>
              </Link>
            </div>
          )
        }

        {
          location?.pathname !== '/' && (
            <div className="nav-wrapper_item">
              <button type="button" onClick={() => navigate(-1)}>
                <LeftChevron />
                <span>Retour</span>
              </button>
            </div>
          )
        }
      </div>
      <div className="drawer-footer">
        <button type="button" className="drawer-footer_item" onClick={() => logOut()}>
          <TurnOff />
          <span>Déconnexion</span>
        </button>
        <Link to="/me" className={`drawer-footer_item ${location?.pathname === '/me' ? 'active' : ''}`}>
          <User />
          <span>Mon profil</span>
        </Link>

      </div>

    </StyledDrawer>
  );
};

export default Drawer;
