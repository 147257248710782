import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigate, Outlet } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import axios from '../../utils/axios';
import { StyledHome } from './style';
import Spinner from '../../component/Spinner';
import Layout from '../../component/Layout';

const Home = () => {
  const navigate = useNavigate();
  const user = useLoaderData();
  return (
    <Layout>
      <StyledHome>
        <h1>Take QR</h1>
        <Outlet />
      </StyledHome>
    </Layout>
  );
};

export default Home;
