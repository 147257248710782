import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import React from 'react';
import axios from '../../../utils/axios';
import { StyledSignUp } from './style';

const Singup = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const navigate = useNavigate();

  const onSubmit = async (data = {}) => {
    try {
      await axios.post('/sign-up', { ...data });
      toast.success('Veuillez consulter vos mails pour finir votre inscription');
      navigate('/login');
    } catch (error) {
      toast.error('Email incorrecte !');
      console.error(error);
    }
  };

  return (
    <StyledSignUp>
      <h1>Inscription</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-wrapper">
          <label htmlFor="lastname">Nom *</label>
          <input
            type="text"
            id="lastname"
            {...register('lastname', { required: true })}
          />
          {errors.lastname && <p className="error">Ce champs est obligatoire</p>}
        </div>
        <div className="input-wrapper">
          <label htmlFor="firstname">Prénom *</label>
          <input
            type="text"
            id="firstname"
            {...register('firstname', { required: true })}
          />
          {errors.firstname && <p className="error">Ce champs est obligatoire</p>}
        </div>
        <div className="input-wrapper">
          <label htmlFor="email">E-mail *</label>
          <input
            type="text"
            id="email"
            {...register('email', { required: true })}
          />
          {errors.email && <p className="error">Ce champs est obligatoire</p>}
        </div>
        <button type="submit">S'inscrire</button>
        <Link to={'/login'}>Annuler</Link>
      </form>
    </StyledSignUp>
  );
};
export default Singup;
