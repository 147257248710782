import React, { useEffect, useState } from 'react';
import {
  useLoaderData, useNavigate, Outlet, useParams,
} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { StyledManageApps } from './style';
import Layout from '../../../component/Layout';
import Form from '../../../component/Form';
import Input from '../../../component/Input';
import axios from '../../../utils/axios';

const ManageApps = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    register,
    setValue,
    getValues,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (id) {
      const getApps = async () => {
        try {
          const resp = await axios.get(`/apps/${id}`);
          setValue('name', resp?.data?.name);
          setValue('client', resp?.data?.client);
          setValue('url', resp?.data?.url);
          setValue('id', resp?.data?._id);
        } catch (e) {
          toast.error('erreur');
          navigate('/apps');
          console.log(e);
        }
      };
      getApps();
    }
  }, []);

  return (
    <Layout>
      <StyledManageApps>
        <Form resource="apps" handleSubmit={handleSubmit} reset={reset} getValues={getValues}>
          <Input name="name" register={register} errors={errors} required label="Nom" />
          <Input name="client" register={register} errors={errors} required label="Client" />
          <Input name="url" register={register} errors={errors} required label="Url" />
          <button type="submit">{id ? 'ENREGISTRER' : 'CREER'}</button>
        </Form>
      </StyledManageApps>
    </Layout>
  );
};

export default ManageApps;
