import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { colors } from '../../utils/globals';
import { StyledEnum } from './style';

// @ts-ignore
const Enum = ({
  label, name, register, errors, control, options,
}) => (
  <StyledEnum>
    <label>{label}</label>
    <Controller
      name={name}
      control={control}
      defaultValue={options[0].value}
      render={({ field, fieldState }) => (
        <div>
          <select {...field}>
            {
              options?.map((option) => (
                <option key={option?.id} value={option?.value}>
                  {option.label}
                </option>
              ))
            }
          </select>
          {fieldState.error && (
            <p>{fieldState.error.message}</p>
          )}
        </div>
      )}
    />
    {errors[name] && <p>{errors[name].message}</p>}
  </StyledEnum>
);

Enum.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.any.isRequired,
  control: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default Enum;
