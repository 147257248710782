import styled from 'styled-components';
import { colors } from '../../utils/globals';

export const StyledUsers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

export default StyledUsers;
