import styled from 'styled-components';
import { colors } from '../../../utils/globals';

export const StyledAddBtn = styled.div`
  position: fixed;
  top: 150px;
  right: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: ${colors.blaze};
  box-shadow: 0 1px 4px 2px ${colors.manatee};
  overflow: hidden;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
      width: 20px;
      height: 20px;
      fill: ${colors.white};
    }
  }
`;

export default StyledAddBtn;
