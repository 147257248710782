import React, { useEffect, useState } from 'react';
import {
  useNavigate, useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledUserDetail } from './style';
import Layout from '../../../component/Layout';
import Spinner from '../../../component/Spinner';
import axios from '../../../utils/axios';

const UserDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState();
  useEffect(() => {
    const getUser = async () => {
      try {
        const resp = await axios.get(`/users/${id}`);
        setUser(resp?.data);
      } catch (e) {
        toast.error('erreur');
        navigate('/users');
        console.log(e);
      }
    };
    getUser();
  }, []);
  return (
    <Layout>
      <StyledUserDetail>
        {
          user ? (
            <>
              <div className="meta">
                <span>Nom:</span>
                <span>{user?.lastname}</span>
              </div>
              <div className="meta">
                <span>Prénom:</span>
                <span>{user?.firstname}</span>
              </div>
              <div className="meta">
                <span>Email:</span>
                <span>{user?.email}</span>
              </div>
            </>
          ) : <Spinner />
        }
      </StyledUserDetail>
    </Layout>
  );
};

export default UserDetail;
