import styled from 'styled-components';
import { colors } from '../../utils/globals';

export const StyledSpinner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export default StyledSpinner;
