import React, { useEffect, useState } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { StyledLayout } from './style';
import Drawer from '../Drawer';

const Layout = ({ children }) => {
  const location = useLocation();
  const getTitle = () => {
    if (location?.pathname === '/') {
      return 'Accueil';
    }
    if (location?.pathname === '/apps') {
      return 'Applications';
    }

    if (location?.pathname.includes('/users')) {
      return 'Utilisateurs';
    }

    if (location?.pathname.includes('/me')) {
      return 'Mon profil';
    }
    return '';
  };
  return (
    <StyledLayout>
      <Drawer />
      <div className="wrapper">
        <div className="page-title">
          <span>{getTitle()}</span>
        </div>
        {children}
      </div>
    </StyledLayout>
  );
};
Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Layout;
