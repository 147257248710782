import styled from 'styled-components';
import { colors } from '../../../utils/globals';

export const StyledUserDetail = styled.div`
  display: flex;
  flex-direction: column;
  padding: 70px 50px;
  gap: 20px;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  .meta {
    display: flex;
    width: 500px;
    gap: 20px;

    span {
      font-weight: 700;
      font-size: 1.6rem;

      &:first-child {
        color: ${colors.thunder};
      }
    }
  }

  .users-wrapper {
    padding: 0 50px;

    li {
      list-style: circle;

      a {
        color: ${colors.manatee};

        &:visited {
          color: ${colors.manatee};
        }
      }
    }
  }

  button {
    background: ${colors.blaze};
    color: ${colors.white};
    font-weight: 700;
    padding: 10px 30px;
    border-radius: 5px;

    &:hover {
      background: ${colors.crusta};
    }
  }
`;

export default StyledUserDetail;
