import styled from 'styled-components';
import { colors } from '../../utils/globals';

export const StyledEnum = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 500px;

  label {
    color: ${colors.manatee};
    font-weight: 700;
    font-size: 1.5rem;
  }

  select {
    padding: 10px 0 10px 10px;
    border-radius: 5px;
    border: 2px solid ${colors.manatee};
    width: 100%;

    &:focus {
      outline: none !important;
    }
  }

  p {
    font-weight: 700;
    color: ${colors.blaze};
  }
`;

export default StyledEnum;
