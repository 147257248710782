import styled from 'styled-components';
import { colors } from '../../utils/globals';

export const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 50px 10%;
  width: 100%;

  table {
    width: 100%;
    background-color: ${colors.white};
    border-collapse: collapse;
    color: #000;
    font-size: 18px;
    border-radius: 8px;
    box-shadow: 0 0 10px 4px ${colors.manatee};


    tr {
      border-radius: 8px;

      td, th {
        padding: 20px 20px;
        border: 0;
      }

      th {
        text-align: left;
        background-color: ${colors.blaze};
        color: ${colors.white};
        padding: 15px 20px;
      }

      td {
        border-bottom: 1px solid ${colors.blaze};

        span {
          font-size: 1.5rem;
          font-weight: 700;
          color: ${colors.emperor};
        }

        &:last-child {
          display: flex;
          align-items: center;
          gap: 20px;

          a, button {
            background: transparent;
            padding: 0;
            margin: 0;

            svg {
              width: 20px;
              height: 20px;
              background: none;
              fill: ${colors.crusta};

              path {
                fill: ${colors.crusta};
              }
            }
          }
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
`;

export default StyledList;
