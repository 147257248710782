import React from 'react';
import './App.scss';
import { ToastContainer } from 'react-toastify';
import GlobalStyle from './utils/globalStyle';
import Router from './Router';
import 'react-toastify/dist/ReactToastify.css';

const App = () => (
  <div className="App">
    <GlobalStyle />
    <ToastContainer
      autoClose={3000}
      position="top-right"
      hideProgressBar={false}
    />
    <Router />
  </div>
);

export default App;
