import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigate, Outlet } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import axios from '../../utils/axios';
import { StyledApps } from './style';
import Spinner from '../../component/Spinner';
import Layout from '../../component/Layout';
import List from '../../component/List';

const fakeData = [
  {
    name: 'aa', client: 'bb', url: 'https://hello-app.takeqr.sevatest.fr', _id: 'dsqdjazxx874',
  },
  {
    name: 'aa', client: 'bb', url: 'https://hello-app.takeqr.sevatest.fr', _id: 'dsqdjazxx874',
  },
  {
    name: 'aa', client: 'bb', url: 'https://hello-app.takeqr.sevatest.fr', _id: 'dsqdjazxx874',
  },
  {
    name: 'aa', client: 'bb', url: 'https://hello-app.takeqr.sevatest.fr', _id: 'dsqdjazxx874',
  },
  {
    name: 'aa', client: 'bb', url: 'https://hello-app.takeqr.sevatest.fr', _id: 'dsqdjazxx874',
  },
  {
    name: 'aa', client: 'bb', url: 'https://hello-app.takeqr.sevatest.fr', _id: 'dsqdjazxx874',
  },
  {
    name: 'aa', client: 'bb', url: 'https://hello-app.takeqr.sevatest.fr', _id: 'dsqdjazxx874',
  },
  {
    name: 'aa', client: 'bb', url: 'https://hello-app.takeqr.sevatest.fr', _id: 'dsqdjazxx874',
  },
  {
    name: 'aa', client: 'bb', url: 'https://hello-app.takeqr.sevatest.fr', _id: 'dsqdjazxx874',
  },
  {
    name: 'aa', client: 'bb', url: 'https://hello-app.takeqr.sevatest.fr', _id: 'dsqdjazxx874',
  },
  {
    name: 'aa', client: 'bb', url: 'https://hello-app.takeqr.sevatest.fr', _id: 'dsqdjazxx874',
  },
  {
    name: 'aa', client: 'bb', url: 'https://hello-app.takeqr.sevatest.fr', _id: 'dsqdjazxx874',
  },
  {
    name: 'aa', client: 'bb', url: 'https://hello-app.takeqr.sevatest.fr', _id: 'dsqdjazxx874',
  },
  {
    name: 'aa', client: 'bb', url: 'https://hello-app.takeqr.sevatest.fr', _id: 'dsqdjazxx874',
  },
  {
    name: 'aa', client: 'bb', url: 'https://hello-app.takeqr.sevatest.fr', _id: 'dsqdjazxx874',
  },
];
const Apps = () => {
  const navigate = useNavigate();
  const user = useLoaderData();
  const [apps, setApps] = useState();
  const [renderCounter, setRenderCounter] = useState(0);
  const refresh = () => {
    setRenderCounter(renderCounter + 1);
  };
  const getApps = async () => {
    setApps(null);
    try {
      const resp = await axios.get('/apps');
      setApps(resp?.data || []);
      // setApps(fakeData);
    } catch (e) {
      // setApps(fakeData);
      console.log(e);
    }
  };

  useEffect(() => {
    getApps();
  }, [renderCounter]);
  return (
    <Layout>
      <StyledApps>
        {
          apps ? (
            <List
              refresh={refresh}
              resource={'apps'}
              data={apps}
              fields={[
                { key: 'name', label: 'Nom' },
                { key: 'client', label: 'Client' },
                { key: 'url', label: 'Url' },
              ]}
            />
          ) : (<Spinner />)
        }
        <Outlet />
      </StyledApps>
    </Layout>
  );
};

export default Apps;
