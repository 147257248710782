import React from 'react';
import {
  createBrowserRouter, RouterProvider,
} from 'react-router-dom';
import Login from './pages/auth/Login';
import RequestPassword from './pages/auth/RequestPassword';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import ResetPassword from './pages/auth/ResetPassword';
import SignUp from './pages/auth/SignUp';
import axios from './utils/axios';
import Users from './pages/Users';
import Apps from './pages/Apps';
import AppDetail from './pages/Apps/Detail';
import UserDetail from './pages/Users/Detail';
import ManageUsers from './pages/Users/Manage';
import ManageApps from './pages/Apps/Manage';

const connected = async () => {
  const localUser = localStorage.getItem('user') || false;
  const tmpUser = JSON.parse(localUser);
  const accessToken = localStorage.getItem('access_token');
  if (tmpUser && accessToken) {
    const me = await axios.get(`/users/me/${tmpUser._id}`);
    localStorage.setItem('user', JSON.stringify(me?.data));
    return { ...me?.data };
  }
  throw new Response('', {
    status: 401,
    statusText: 'Not Authorized',
  });
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <NotFound />,
    loader: connected,
  },
  {
    path: '/users',
    element: <Users />,
    errorElement: <NotFound />,
    loader: connected,
  },
  {
    path: '/users/create',
    element: <ManageUsers />,
    errorElement: <NotFound />,
    loader: connected,
  },
  {
    path: '/users/edit/:id',
    element: <ManageUsers />,
    errorElement: <NotFound />,
    loader: connected,
  },
  {
    path: '/users/:id',
    element: <UserDetail />,
    errorElement: <NotFound />,
    loader: connected,
  },
  {
    path: '/apps/:id',
    element: <AppDetail />,
    errorElement: <NotFound />,
    loader: connected,
  },

  {
    path: '/apps',
    element: <Apps />,
    errorElement: <NotFound />,
    loader: connected,
  },
  {
    path: '/apps/create',
    element: <ManageApps />,
    errorElement: <NotFound />,
    loader: connected,
  },
  {
    path: '/apps/edit/:id',
    element: <ManageApps />,
    errorElement: <NotFound />,
    loader: connected,
  },
  {
    path: '/login',
    element: <Login />,
    errorElement: <NotFound />,
  },
  {
    path: '/sign-up',
    element: <SignUp />,
    errorElement: <NotFound />,
  },
  {
    path: '/request-password',
    element: <RequestPassword />,
    errorElement: <NotFound />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    errorElement: <NotFound />,
  },
]);

const Router = () => (
  <RouterProvider router={router} />
);

export default Router;
