import React, { useEffect, useState } from 'react';
import {
  useLoaderData, useNavigate, Outlet, useParams, Link,
} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { StyledAppDetail } from './style';
import Layout from '../../../component/Layout';
import CreateForm from '../../../component/Form';
import Input from '../../../component/Input';
import Spinner from '../../../component/Spinner';
import axios from '../../../utils/axios';

const AppDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [app, setApp] = useState();
  useEffect(() => {
    const getApps = async () => {
      try {
        const resp = await axios.get(`/apps/${id}`);
        setApp(resp?.data);
      } catch (e) {
        toast.error('erreur');
        navigate('/apps');
        console.log(e);
      }
    };
    getApps();
  }, []);
  return (
    <Layout>
      <StyledAppDetail>
        {
          app ? (
            <>
              <div className="meta">
                <span>Nom:</span>
                <span>{app?.name}</span>
              </div>
              <div className="meta">
                <span>Client:</span>
                <span>{app?.client}</span>
              </div>
              <div className="meta">
                <span>Url:</span>
                <span>{app?.url}</span>
              </div>
              <div className="meta">
                <span>Participants:</span>
              </div>

              <ul className="users-wrapper">
                {
                  _.get(app, 'users', []).map((user) => (
                    <li>
                      <Link
                        to={`/users/${user?._id}`}
                        key={user?.id}
                      >
                        {`${user?.firstname} - ${user?.lastname} - ${user?.email}`}
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </>
          ) : <Spinner />
        }
      </StyledAppDetail>
    </Layout>
  );
};

export default AppDetail;
