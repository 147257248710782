import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { StyledManageUsers } from './style';
import Layout from '../../../component/Layout';
import Form from '../../../component/Form';
import Input from '../../../component/Input';
import Enum from '../../../component/Enum';
import axios from '../../../utils/axios';

const ManageUsers = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    register,
    setValue,
    getValues,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (id) {
      const getUser = async () => {
        try {
          const resp = await axios.get(`/users/${id}`);
          setValue('firstname', resp?.data?.firstname);
          setValue('lastname', resp?.data?.lastname);
          setValue('email', resp?.data?.email);
          setValue('roles', resp?.data?.roles);
          setValue('id', resp?.data?._id);
        } catch (e) {
          toast.error('erreur');
          navigate('/users');
          console.log(e);
        }
      };
      getUser();
    }
  }, []);
  return (
    <Layout>
      <StyledManageUsers>
        <Form resource="users" handleSubmit={handleSubmit} reset={reset} getValues={getValues}>
          <Input name="lastname" register={register} errors={errors} required label="Nom" />
          <Input name="firstname" register={register} errors={errors} required label="Prénom" />
          <Input name="email" register={register} errors={errors} required label="Email" />
          <Input name="password" register={register} errors={errors} label="Mot de passe" />
          <Enum
            name="roles"
            control={control}
            register={register}
            errors={errors}
            label="Rôle"
            options={[
              { label: 'Super Admin', value: 'SuperAdmin' },
              { label: 'Admin', value: 'Admin' },
              { label: 'Utilisateur', value: 'User' },
            ]}
          />
          <button type="submit">{id ? 'ENREGISTRER' : 'CREER'}</button>
        </Form>
      </StyledManageUsers>
    </Layout>
  );
};

export default ManageUsers;
