import React from 'react';
import { PulseLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { colors } from '../../utils/globals';
import { StyledAddBtn } from './style';
import { ReactComponent as Plus } from '../../../svgs/plus.svg';

// @ts-ignore
const AddBtn = ({ resource }) => (
  <StyledAddBtn>
    <Link to={`/${resource}/create`}>
      <Plus />
    </Link>
  </StyledAddBtn>
);
AddBtn.propTypes = {
  resource: PropTypes.string.isRequired,
};

export default AddBtn;
