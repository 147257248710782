import styled from 'styled-components';
import { colors } from '../../utils/globals';

export const StyledDrawer = styled.div`
  height: 100vh;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${colors.white};
  box-shadow: 4px 0 4px 0 ${colors.manatee};

  .logo-wrapper {
    min-height: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 4px 0 ${colors.manatee};

    figure {
      width: 95px;
      height: 95px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    svg {
      width: 80px;
      height: 80px;
    }
  }

  .nav-wrapper {
    padding-top: 50px;
    height: 100vh;
    display: flex;
    flex-direction: column;

    &_item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: ${colors.blaze};
      position: relative;

      a, button {
        padding-left: 20px;
        height: 50px;
        width: 100%;
        font-size: 1.1rem;
        color: ${colors.thunder};
        text-transform: uppercase;
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: 20px;

        svg {
          margin-bottom: 5px;
          z-index: 99;
          width: 25px;
          height: 25px;
        }
      }

      &:before {
        z-index: 1;
        display: none;
        position: absolute;
        content: "";
        width: 5px;
        height: 100%;
        right: 0;
        background: ${colors.blaze};
      }

      &:hover, &.active {
        a, button {
          color: ${colors.blaze};

          svg {
            fill: ${colors.blaze};
            color: ${colors.blaze};
            animation: slideAndReturn 1s ease-in-out;

            path {
              color: ${colors.blaze};
              fill: ${colors.blaze};
            }
          }
        }

        &:before {
          display: block;
        }
      }
    }
  }

  @keyframes slideAndReturn {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateX(0);
    }
  }

  .drawer-footer {
    min-height: 100px;
    display: flex;
    flex-direction: column-reverse;
    box-shadow: 0 -4px 4px 0 ${colors.manatee};
    background: ${colors.blaze};

    &_item {
      height: 50px;
      padding-left: 10px;
      background: ${colors.blaze};
      display: flex;
      align-items: center;
      gap: 20px;

      svg {
        margin-bottom: 5px;
        width: 25px;
        height: 25px;
        fill: ${colors.white};

        path {
          fill: ${colors.white};
        }
      }

      span {
        font-size: 1.2rem;
        font-weight: 700;
        color: ${colors.white};
        text-transform: uppercase;
      }

      &:hover {
        background: ${colors.athens};

        span {
          color: ${colors.blaze};
        }

        svg {
          path {
            fill: ${colors.blaze};
          }
        }
      }
    }
  }
`;

export default StyledDrawer;
