import React, { useState } from 'react';
import { PulseLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../utils/globals';
import { StyledForm } from './style';
import axios from '../../utils/axios';
import Spinner from '../Spinner';

const Form = ({
  children, resource, handleSubmit, reset, getValues,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState();
  const submitResource = async (data) => {
    setIsLoading(true);
    try {
      const id = getValues('id');
      if (id) {
        await axios.put(`/${resource}/${id}`, data);
        navigate(`/${resource}`);
        toast.success('Modifié avec succès !');
      } else {
        await axios.post(`/${resource}`, data);
        navigate(`/${resource}`);
        toast.success('Créé avec succès !');
      }
    } catch (e) {
      reset();
      toast.error(e?.response?.data?.message?.includes('Email already exist') ? 'Adresse email déjà utilisée' : 'Erreur');
      setIsLoading(false);
    }
  };
  return (
    <StyledForm onSubmit={handleSubmit(submitResource)}>
      {!isLoading ? children : <Spinner />}
    </StyledForm>
  );
};

Form.propTypes = {
  resource: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Form;
