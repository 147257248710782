import styled from 'styled-components';
import { colors } from '../../utils/globals';

export const StyledNotFound = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.athens};

  h1 {
    font-size: 5rem;
  }

  span {
    font-size: 2rem;
  }
`;

export default StyledNotFound;
