import styled from 'styled-components';
import { colors } from '../../utils/globals';

export const StyledLayout = styled.div`
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    .page-title {
      min-height: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      padding-left: 50px;
      box-shadow: 0 4px 4px 0 ${colors.manatee};
      z-index: 99;

      span {
        font-size: 3rem;
        font-weight: 700;
        text-transform: uppercase;
        color: ${colors.blaze};
      }
    }
  }
`;

export default StyledLayout;
