import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../utils/globals';
import { StyledInput } from './style';

// @ts-ignore
const Input = ({
  label, name, register, required, errors,
}) => (
  <StyledInput>
    <label>{label}</label>
    <input
      type="text"
      {...register(name, { required: required ? 'Ce champ est requis' : false })}
    />
    {errors[name] && <p>{errors[name].message}</p>}
  </StyledInput>
);

Input.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.any.isRequired,
  required: PropTypes.bool,
};

Input.defaultProps = {
  required: false,
};

export default Input;
